.bar {
  position: absolute;
  width: 100%;
  height: 10px;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.bar__left {
  position: absolute;
  width: 50%;
  height: 10px;
  bottom: 0;
  left: 0;
  background-color: $pelorous;
}

.bar__right {
  position: absolute;
  width: 50%;
  height: 10px;
  right: 0;
  bottom: 0;
  background-color: $maroon-flush;
}

.bar--top {
  top: 0;
  bottom: auto;
}

.bar--white {
  .bar__left {
    background-color: $white;
  }
}

.bar--maroon-flush {
  .bar__left {
    background-color: $maroon-flush;
  }

  .bar__right {
    background-color: $maroon-flush;
  }
}
