$expanding-bold: "Expanding-Bold", Helvetica Neue, Helvetica, Arial, sans-serif;
$helvetica: Helvetica Neue, Helvetica, Arial, sans-serif;

a {
  color: $maroon-flush;

  &:hover {
    color: $maroon-flush-hover;
  }
}
