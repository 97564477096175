.btn-maroon-flush {
  color: $white;
  background-color: $maroon-flush;
  border-color: $maroon-flush;

  &:hover {
    color: $white;
    background-color: $maroon-flush-hover;
    border-color: $maroon-flush-hover;
  }

  &:focus {
    color: $white;
    background-color: $maroon-flush-hover;
    border-color: $maroon-flush-hover;
    box-shadow: 0 0 0 0.2rem rgba(178, 27, 80, 0.5);
  }

  &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: $maroon-flush-active;
    border-color: $maroon-flush-active;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(178, 27, 80, 0.5);
    }
  }
}

.btn-pelorous {
  color: $white;
  background-color: $pelorous;
  border-color: $pelorous;

  &:hover {
    color: $white;
    background-color: $pelorous-hover;
    border-color: $pelorous-hover;
  }

  &:focus {
    color: $white;
    background-color: $pelorous-hover;
    border-color: $pelorous-hover;
    box-shadow: 0 0 0 0.2rem rgba(90, 197, 201, 0.5);
  }

  &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: $pelorous-active;
    border-color: $pelorous-active;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 197, 201, 0.5);
    }
  }
}

.btn-download {
  padding: 15px;
  margin: 15px 0;
  font-family: $helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  border-color: $silver;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    border-color: $silver;
  }
}
