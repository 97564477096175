$abbey: #575b61;
$black: #000;
$iceberg: #d6f0f1;
$maroon-flush: #b21b50;
$maroon-flush-hover: #8e1640;
$maroon-flush-active: #6b1030;
$pelorous: #31b6bb;
$pelorous-hover: #279296;
$pelorous-active: #1d6d70;
$powder-blue: #aee2e4;
$silver: #ccc;
$white: #fff;
$orangeRed: #ff4500;

// BREEAM colors
$apple: #55b147;
$black-haze: #f1f2f2;
$manatee: #9a9da0;
