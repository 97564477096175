.investments__wrapper {
  padding: 35px 0 55px;
  border-top: 1px solid $maroon-flush;
}

.investments__title {
  margin: 0 0 25px;
  color: $white;
  font-family: $helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: justify;

  b,
  strong {
    font-family: $helvetica;
    font-weight: 500;
  }
}

.investments__illustration {
  display: block;
  margin: 0 auto;
}

.investments__related {
  display: inline-block;
  padding: 5px 15px;
  margin: 0 0 10px;
  background-color: $apple;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: $white;
  font-family: $expanding-bold;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  border-radius: 6px;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}

.investments__item {
  position: relative;
  margin: 0 0 10px;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }

  &:first-child {
    &::before {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        content: " ";
        background-color: $maroon-flush;
      }

      @include media-breakpoint-up(xl) {
        left: -15px;
      }
    }
  }
}

.investments__text {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  margin: 0;
  height: 100%;
  color: $maroon-flush;
  font-family: $helvetica;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  background-color: $white;
  border-radius: 15px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;

  @include media-breakpoint-up(xl) {
    padding: 25px 20px;
    font-size: 16px;
    line-height: 18px;
  }
}
