label {
  margin-bottom: 3px;
  color: $maroon-flush;
  font-family: $helvetica;
  font-weight: 700;
  font-size: 12px;

  span {
    color: $apple;
  }
}

.form-group {
  margin: 0 0 5px;
}

.form-control {
  border: 1px solid $silver;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.form-check-input {
  margin-top: 0;
}

.form-check-label {
  color: $abbey;
  font-family: $helvetica;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
