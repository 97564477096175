.mega-highlight {
  display: block;
  padding: 15px;
  margin: 0;
  color: $white;
  font-family: $helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  background-color: $apple;
  border-radius: 3px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
