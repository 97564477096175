.full-report {
  padding: 0 0 35px;
}

.full-report__highlight {
  margin: 0 0 35px;
}

.full-report__title {
  margin: 0 0 15px;
  color: $white;
  font-family: $expanding-bold;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.full-report__wrapper {
  padding: 15px 30px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid $silver;
}

.full-report__required {
  margin: 0 0 15px;
  color: $maroon-flush;
  font-size: 12px;
  line-height: 1;
}

.full-report__message {
  margin: 0 0 5px;
  color: $orangeRed;
  font-family: $expanding-bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.full-report__message--success {
  margin: 0;
  padding: 50px 0;
  color: $maroon-flush;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  text-align: center;

  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
}
