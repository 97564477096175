.footer {
  position: relative;
  border-top: 55px solid $black-haze;
  background-color: $white;

  &:before {
    position: absolute;
    top: -55px;
    left: 0;
    width: 100%;
    height: 10px;
    content: "";
    background-color: $apple;
  }
}

.footer__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 35px 0;
}

.footer__brand {
  margin: 0;
  color: $maroon-flush;
  font-family: $expanding-bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.footer__copyright {
  margin: 0;
  color: $abbey;
  font-family: $expanding-bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}
