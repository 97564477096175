.hero {
  position: relative;
}

.hero__background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 77%;
  background-color: $manatee;

  @include media-breakpoint-up(lg) {
    height: 425px;
  }

  &::before {
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 165px;
      content: " ";
      background-color: $black-haze;
    }
  }
}

.hero__header {
  padding: 35px 0 45px;
  position: relative;
}

.hero__title {
  position: relative;
}

.hero__hr {
  margin-top: 25px;
  margin-bottom: 25px;
  border: 0;
  border-top: 1px solid $white;
}

.hero__logo {
  display: block;
  margin: 0 auto 30px;
  max-width: 430px;
  width: 100%;
  height: auto;

  @include media-breakpoint-up(lg) {
    margin: 0 0 90px;
  }
}

.hero__title-primary {
  margin: 0 0 2px;
  color: $abbey;
  font-family: $expanding-bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}

.hero__title-secondary {
  margin: 0 0 30px;
  color: $maroon-flush;
  font-family: $expanding-bold;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }

  span {
    color: $abbey;
  }
}

.hero__badge {
  display: block;
  margin: 0 auto 30px;
  max-width: 180px;
  width: 100%;
  height: auto;

  @include media-breakpoint-up(lg) {
    margin: 0 0 30px;
  }
}

.hero__title-tertiary {
  margin: 0 0 30px;
  color: $white;
  font-family: $expanding-bold;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    margin: 0 0 166px;
    text-align: left;
  }
}

.hero__brochure {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    position: absolute;
    right: -170px;
    bottom: -40px;
    max-width: 480px;
    width: 100%;
    height: auto;
  }

  @include media-breakpoint-up(xl) {
    right: -120px;
  }
}
