.bg-white {
  background-color: $white;
}

.bg-pelorous {
  background-color: $pelorous;
}

.bg-powder-blue {
  background-color: $powder-blue;
}

.bg-manatee {
  background-color: $manatee;
}
